
#selectionSlots{
    width:90%;
    background-color: white;
    /* height: fit-content; */
    /* min-height:90vh; */
    height:fit-content;
}
.storesAll{
    margin-top:72px !important;
}
.btn-active{
    position: relative;
    outline:none;
    border:none;
   border-bottom:3px solid var(--color_purple) !important;

}
.s-ful-btn{
    font-size: .9em!important;
}
.typeFul{
    line-height: 40px;
    margin-left:30px;
    font-size: .8em;
}
.btn-active:focus{
    outline:none;
}
.btn-deactive{
    outline:none;
    border:none;
    border-bottom:0px solid #3E3665;

}

.cat-btn-active{
   text-decoration: underline;
   font-weight: bold;
}
.cat-btn-deactive{
    text-decoration: none;
}
.s-cat-btn{
    border-radius: 5px;
    outline:none;
      margin-left:7px;
      font-size:.9em ;
      padding:3px 10px;
      text-align: center;
}

.ss-cat-btn{
   width:fit-content!important;
   white-space: nowrap; 
    outline:none;
    border:none;
    color:white;
      margin-right:8px;
      font-size:.9em ;
      padding:3px 10px;
      text-align: center;
      background:transparent;
}
.shop_button{
    width:fit-content!important;
     outline:none;
     border:none;
     color:blue;
       font-size:.7em ;
       border:1px solid white;
       padding:2px 8px;
       text-align: center;
       background:rgb(236, 235, 235, .8);
       margin-bottom: 0.25rem;
}
.ss-cat-btn:focus{
    outline: none;
}
.s-cat-bar{
    overflow:scroll;
    background:var(--color_purple);
    box-shadow:2px  0px 5px 1px rgb(240, 240, 240);
}
.s-inner-cat-bar{
    width:fit-content;
}
.zipcode-btn{
    position:relative;
    border:1px solid #3E3665;
    background: white;
    color:#3E3665;
    border-radius: 5px;
    outline:none;
      margin-left:7px;
      font-size:.83em ;
      padding:0px 10px;
      text-align: center;
}
.zipcode-btn .zipcodeForm{
    width:80px;
    margin-left:-25px;
    text-align:left;
}
.zipcode-btn .zipcodeForm .zipInput{
    width:60px;
   margin-left:-20px;
}
#zipInput::placeholder{
    color:#3E3665;
    background:transparent;
}

#browserss{
    font-weight:lighter;
}
.icon-down{
    top:0;
    width:20%;
    line-height: 2em;
    position:absolute;
    right:0;
    height:100%;
    background:silver;
    color:var(--color_purple);
    justify-content:flex-end;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.dropdown-toggle{
   color: var(--color_purple);
    background:silver;
    border-top-left-radius: 3px!important;
    border-bottom-left-radius: 3px !important;
}

.view-stores{
    color:var(--color_purple);
    font-weight: bolder;
    cursor: pointer;
}
.BZPopup_location{
    font-size: 0.9rem;
    line-height: 1em;
    text-align: left;
}
.BZPopup_location_div{
    /* margin-top:-10px!important; */
    height:auto;
    padding-bottom: 10px;
}
.store-name{
    color:var(--color_purple)
}
.card-title{
    color:var(--color_purple);
  

}
.card-titleBZ{
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow:"ellipsis";
    height: 20px !important;

}
.one_store_top{
    position: relative;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.business-logo-address{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.5%;
    /* width:100%;
    height:100%; */
}
/* .{
   padding:3px 20px;
   border-radius:10px;
   border:1px dotted grey;
    background:rgba(242, 242, 242, 0.7);
    color:var(--color_purple)
} */
.logo-store{
    height:80px;
    width:80px;
    background: white;
    border-radius: 50%;
}
.time-box,.time-box-selected,.time-box-select{
    margin:2px;
    font-size:.7em;
    line-height: .7em;
    position: relative;
    text-align: center;
    height:80px;
    width:150px;
}
.Ful_Cart .time-box, .Ful_Cart .time-box-selected, .Ful_Cart .time-box-select{
    margin:2px;
    font-size:.6em;
    line-height: .5em;
    position: relative;
    text-align: center;
    height:65px;
    width:140px;
    border-radius: 5px;
    overflow: hidden;
}
.time-box{
   border:1px solid var(--color_purple);
   color:var(--color_purple);
}

.time-box-selected{
   color:white;
   border:1px solid var(--color_purple);
   background:var(--color_purple);
}

.time-box-select{
   color:white;
   border:1px solid var(--color_lightpurple);
   background:var(--color_lightpurple);
}

.time-box .availablee{
    color: green !important;
}
.time-box-select .availablee{
    color: var(--color_purple) !important;
}
.lh-16{line-height: 16px;}
.heading-date-time{
    color:var(--color_purple);
    font-size: 0.9em;
}
.date-box, .date-box-select, .date-box-selected {
    position:relative;
    font-size: .7em !important;
    text-align: center;
   height:90px;
   width:110px;
   border:none;
   margin:2px;
   padding:0;
   /* overflow: hidden; */
}
.Ful_Cart .date-box, .Ful_Cart .date-box-select, .Ful_Cart .date-box-selected {
    position:relative;
    font-size: .7em !important;
    text-align: center;
   height:80px;
   width:105px;
   border:none;
   margin:2px;
   padding:0;
   /* overflow: hidden; */
}
.date-box .dt_upper{
height:37%;
width:100%;
background:silver!important;
color:var(--color_purple);
border-top-left-radius:10px;
border-top-right-radius:10px;
}

.date-box .dt_lower{
    height:63%;
    width:100%;
    background:white;
    border-left:1px solid silver;
    border-right:1px solid silver;
    border-bottom:1px solid silver;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    color:silver;
}
.date-box-selected .dt_upper{
    height:37%;
     width:100%;
    color:white!important;
    border-top-left-radius:10px;
border-top-right-radius:10px;
    background:var(--color_purple)!important;
 }
.date-box-selected .dt_lower{
    height:63%;
    width:100%;
    background: white;
    border-left:1px solid silver;
    border-right:1px solid silver;
    border-bottom:1px solid silver;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    color:var(--color_purple);
}

.date-box-select .dt_upper{
    height:37%;
     width:100%;
     color:white!important;
     border-top-left-radius:10px;
border-top-right-radius:10px;
     background:var(--color_lightpurple)!important;
 }
.date-box-select .dt_lower{
    height:63%;
    width:100%;
    background: white;
    border-left:1px solid silver;
    border-right:1px solid silver;
    border-bottom:1px solid silver;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    color:var(--color_purple);
}
.time-box, .time-box-select {
    line-height: .7em;
 font-size:.8em;
}
.tm_upper .sltTime{
    line-height: 1.8em;
    font-size: 1.6em;
    font-weight: bold;
}
.tm_lower{
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
}
.fbtns[rel="pick-fulfilment"] {
    justify-content: center !important;
}
.store-schedules{
    overflow-y:auto;
    height:100%;
}
.schedule-btn-on.store-schedules{
    height:calc(100% - 40px);
} 

.schedule-btn-off.store-schedules{
    height:calc(100% - 85px);
}
.accordion-button{
    border: none !important;
    background: rgb(224, 224, 224) !important;
}
.default-address{
    cursor:pointer;
}
#head-acc1, #head-acc2 , #head-acc3{
    position: absolute;
    right: 35px;
    font-size:1em;
    width:150px;
    display:none!important;
}
.accordion-button.collapsed #head-acc1, .accordion-button.collapsed #head-acc2, .accordion-button.collapsed #head-acc3{
    display:block!important;
}
.headingOne.collapsed #head-acc1, .headingTwo.collapsed #head-acc2, .headingThree.collapsed #head-acc3{
    display:block!important;
}
.business-text {
    color: black;
}
#head-acc{
    font-size:1em;
}
#collapseOne, #collapseTwo, #collapseThree{
    border:2px solid #f2f2f2;
    border-bottom-left-radius:5px;
    background: #FAFAFA !important;
    border-bottom-right-radius:5px;
}
#collapseTwo, #collapseThree{
    overflow:scroll;
    /* height:55vh!important; */
} 
#collapseTwo{
    padding-bottom:40px;
    max-height:calc(100vh - 235px);
}
.accordion[stype='Delivery'] #collapseTwo{
    max-height:calc(100vh - 265px);
}
.schedule-btn-box{
    left:0;
    height:60px;
    width:77%;
    left:11.5%;
    background: #FAFAFA !important;
}
#schedule-box, .schedule-box{
    position: absolute;
    bottom: 0px;
    padding: 2px 0px;
    background: #fff;
    /* display: none; */
}

#collapseTwo.show #schedule-box{
    /* display: block; */
}
.goto-store-btn {
    background: #F2F2F2;
    width:180px;
    border-radius: 5px;
    font-size:.9em;
    outline:none;
    border:1px solid #3E3665;
    color:#3E3665;
}
.schedule-btn{
    background: #eeeeee;
    width:180px;
    border-radius: 5px;
    font-size:.9em;
    outline:none;
    border:1px solid #3E3665;
    color:#999999!important;
    display: none !important;
}
.schedule-btn-off.store-schedules .schedule-btn{
    color: #888888!important;
    background: #eeeeee;
    display: block !important;
}
.schedule-btn-on.store-schedules .schedule-btn , .schedule-active-btn{
    color: #F2F2F2!important;
    border:1px solid #3E3665;
    background:#3E3665!important;
    display: block !important;
}
.slot-t-tic{
    color:green;
    border-radius:50%;
    outline:none;
    border:none;
    font-size:.8em;
   margin-left:3px;
    top:3px;
}
.slot-t-tic-active{
    font-size:.7em;
}
.slot-tic-active{
    font-size:.7em;
}
.bz-tick{
    color:rgb(194, 194, 194, .5);
    /* background: rgba(169, 169, 169, 0.5); */
    /* border-radius:50%; */
    background:transparent;
    outline:none !important ;
    border:none;
    /* height:24px;
    width:24px; */
    right:5px;
    /* top:5px; */
    font-size: 0.7rem;
}
.bz-tick-select{
    /* color:var(--color_purple); */
    color:white;
    /* background:white;
    border:1px solid grey; */
}
.s-cross-btn{
    background:transparent;
    border:none;
    outline:none;
        color: var(--color_purple);
        margin-right:25px;
      border-radius: 5px;
      padding:2px 5px;
      height:27px;
      line-height: .0em;
}
#dropdown_bzpop{
    background:white;
    width:175px;
    overflow: hidden;
    white-space: nowrap;
}
#category_dropdown{
    width:150px;
    margin-right:2px !important;
}
.selected-logo{
    height:50px;
    width:auto;
    margin-right:10px;
    border-radius:50%;
}
.selected-icon{
    height:34px;
    width:auto;
    margin-right:10px;
}
.slot-box{
    line-height:14px;
    white-space: nowrap;
}
.slots-head .price-free{
    font-size:.6em;
    font-weight: bold;
}
.price-free{
    color: grey !important;
}

/* dropdown */
.dropdown-toggle-c{
    background: none;
    width:175px;
}
.dropdown-menu-c{
    width:175px;
   font-size:.9em;
}
:after{
    font-size:1.2em;
    line-height:2em;
}
.dropdown-toggle-z{
   margin-left:5px;
    overflow: hidden;
    background: none;
    border: 1px solid var(--color_purple) !important;
    width:155px;
}
.dropdown-menu-z{
    width:155px;
   font-size:.9em;
}
.open-add-popup{
    position:relative;
}
.address-popup_main{
 height: 100vh;
 width: 100vw;
 background: rgba(0, 0, 0, 0.235);
 align-items: center;
 justify-content: center;

}

.address-popup_main .address-popup{
    width: 42vw !important;
    background: white;
    z-index: 3;

}
.address-popup_main .address-popup .address-popup-input{
    width: 100%;
    font-size: 0.9rem;
  border: none; 
}
.address-popup-input-s{
    width: 50%;
    font-size: 0.9rem;
  border: none;  
}
.address-popup_main .address-popup .address-popup-input1{
  border: none;
  width: 95%;
   
}
.each-addr-box{
position:relative;
}
.time-container{
    /* width:fit-content!important;
     margin:auto; */
     color: red;
}
.addr-tick{
    position:absolute;
    height:23px;
    width:23px;
    right:10px;
    top:10px;
    color:rgb(194, 194, 194, .5);
    background: rgba(169, 169, 169, 0.3);
    text-align:center;
    line-height: 1.8em;
    border-radius:50%;
    cursor:pointer;
    font-size:.8em;
}
.credit-card{
    position:absolute;
    height:auto;
    width:44px;
    right:10px;
    border-radius:3px;
    top:10px;
    color:rgb(194, 194, 194, .5);
    background: rgba(169, 169, 169, 0.3);
}
.address-white-box{
    background:white!important;
    height:190px;
}
.payment-white-box{
    background:white!important;
    height:170px;
}
.add-addr-box{
    height:190px;
    background:#F2F2F2;
    border:.5px dotted silver;
}
.add-pay-box{
    height:170px;
    background:#F2F2F2;
    border:.5px dotted silver;
}
.addr-tick-select{
    background:var(--color_lightpurple);
    color:white; 
}

.anotherCarouselBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;

}
.anotherCarouselBtn > .fas{
    cursor: pointer;
}
.address-white-box .card-body{
    height: fit-content !important;
}
.MuiCollapse-wrapperInner{
    box-Shadow:none;
    padding:0;
}
.DeliveryInstructionDiv{
    height: 45px;
}
.DeliveryInstructionH2{
    font-size: .8em; margin-top: 15px;line-height:.5em;color:grey;font-style:italic 
}
.DeliveryInstructionH1{
    font-size: .9em; line-height:.5em;
}
.address-123{
    height:64px;
}
.filterbarIcon{
    font-size: 0.8rem !important;
}
.accordion-button:not(.collapsed){
    color: var(--color_purple) !important;
}
#accordionExample{
    height: calc(100% - 110px);
}
#selectionStores .s-cat-btn{
    margin-top:5px;
}
.bu_text_s{
    line-height: 14px;
    font-size: 11px;
}
.sideIconsDiv{
    width: 80px;
}
.sideIconsDiv > img{
    width: 70px;
}
/*  */
@media screen and (max-width:768px){
    #collapseTwo{
        padding-bottom:40px;
        max-height: calc(100vh - 170px) !important;
    }
    .accordion[stype='Delivery'] #collapseTwo{
        max-height: calc(100vh - 200px) !important;
    }
    .address-123{
        height:78px;
    }
}

/* cart pm popup */
#add-mini-popup{
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    height: 100%;
    background: rgba(0, 0, 0, 0.295);
    top: 0;
    z-index: 1039!important;
    right: 0;
}

.EditMiniPopup > .subMiniPopup{
    width: 95%;
    position: relative;
    height: fit-content;
}
.address-mini-popup-input{
    font-size: 0.8rem;
    font-weight: 300;
}
.address-mini-popup-input-s{
    width: 49%;
    font-size: 0.8rem;
    font-weight: 300;
}
.grid-map-view-bar{
    margin-top: 10px !important;
    top: 0;
    
 }
 /* #selectionStoresFullPage{
     width: 100%;
position: relative;
min-height: 100vh;
 } */


@media screen and (max-width:766px){
    .grid-map-view-bar{
       margin-top: 40px !important;
    }
    .zipcode-dropdown-bzgridmap{
        position: absolute;
        top: 60px;
        /* left: 0%; */
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width:500px){
    .address-mini-popup-input{
        font-size: 0.72rem !important;
    }
    .address-mini-popup-input-s{
        font-size: 0.72rem !important;
    }
   
}
@media screen and (max-width:375px){
    .zipcode-dropdown-bzgridmap{
        font-size: 0.8rem !important;
     }
}
.ADDRESSPOPUP{
    display:none;
}

.mapDiv{
    overflow: hidden !important;
}
.mapDiv .leaflet-container{
    height: 100%;
    width: 100%;
}



