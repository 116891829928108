#MyAccountSideBar{
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}
#MyAccountSideBar > .row{
    display: flex;
    align-items: center;
    padding: 6px 10px;
    position: relative;
    color: grey ;
    position: relative;
    cursor: pointer;
    /* border: 1px solid red; */
}
#MyAccountSideBar > .row > a{
    color: grey;
    position: relative;
}
.MyAccountSideBarSelectedDiv{
    color: var(--color_purple) !important;
    transition: all 0.3s;
}
.MyAccountSideBarSelectedDiv > a{
    color: var(--color_purple) !important;
}
.MyAccountSideBarSelectedDiv::before{
    content: "";
    position: absolute;
    transition: all 0.3s;
    left: 0;
    top: 0;
    background: var(--color_purple);
    width: 5px;
    height: 100%;

}
.UIChevronRight{
    font-size: 1rem !important;
    margin-top: 0.3rem;
}
.h6{
    font-weight: 600 !important;
}
.MyAccountHr{
    position: relative!important;
    top: 0.7rem !important;
    background: rgb(230, 228, 228) !important;
    height: 3vh;
    z-index: 1 ;
}
/* .MyAccountHr{
    position: relative!important;
    top: 0.7rem !important;
    background: rgb(243, 243, 243) !important;
    height: 3vh;
    z-index: 1  ;
} */
.MyAccountHrIcon{
    position: relative;
    /* top: -0.3rem;  */
    width: 1.1rem !important;
    /* border: 1px solid red; */
}
.orderItemImg{
    width:2rem;
    max-height:2rem;
}
.MyAccountImgSm{
    width: 3.5rem;
    height: 3.5rem;
}
.MyAccountStoreImg{
    width: 2rem;
    max-height: 2rem;
}
.MyAccountItemImgSm{
    width: 2.5rem;
    max-height: 2.4rem;
}
.MyAccountHr i.fas{
    font-size: 0.8em;
}
span > small{
    font-weight: 400 !important;
}
u{
    text-decoration: none !important;
}
.MyAccountSideBarRow{
    font-size: 0.8rem !important;
}

#PaymentPopup{
    height: 100vh;
    background: rgba(0, 0, 0, 0.37);
    top: 0;
    display: none;
    align-items: center;
    justify-content: center;
    color:black;
    
}
label{
    font-size: 0.8rem !important;
}
.outlined-basic{
    font-size: 0.8rem !important;
}
.d1-flex{
    display: flex;
}
.row{
    --bs-gutter-x:0 !important;
}
.rounded-1{
    border-radius: 0.45rem;
}
@media screen and(max-width:1200px){
    
}
@media screen and (max-width:768px){
    .h6{
        font-size: 0.7rem;
    }
    .MyAccountItemImg{
        margin-top: 0.6rem;
    }
    span{
        font-size: 0.8rem !important;
    }
    .MyAccountStoreImg{
        width: 3.5rem;
        height: 3.5rem;
        margin-top: 0.3rem;
    }
}
@media screen and (max-width:500px){
    .h6{
        line-height: 0.6rem !important;
       font-weight: 500 !important;
  
    }
    .MyAccountStoreImg{
        width: 3rem;
        height: 3rem;
        margin-top: 0rem;
    }
    span > small{
        font-weight: 400 !important;
    }
    u{
        display: none;
    }
    .MyAccountImgSm{
        width: 2.4rem;
        height: 2.4rem;
    }
}
@media screen and (max-width:990px){
    .d1-flex{
        display: block;
    }
    .orders_main{
        width:100%;
        padding: 0px 1.5em;
    }
}
@media screen and (max-width:350px){
.MyAccountHr > small{
        font-size: 0.5rem !important;
    }
}
@media screen and (max-width:766px){
    .orders_main{
        margin-top:50px;
        padding: 0px;
    }
}
@media screen and (max-width:575px){
    .each-addr-box{
        width: 98% !important;
        margin: 0px auto;
        max-width:350px;
    }
}