.home{
    background-image: url("../assets/rapo_bg.jpg");
    background-size:cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.map_business-main{
    position: relative;  
    width:300px;
}
.map_business{
    position: relative;
   background-color:rgb(77, 75, 75, .5);
    transition: 0.2s all;
    z-index:1!important;
    width:300px;
     z-index:15;
     height:100%;
}
.map_business_up{
    position: relative;
/* background-color:rgb(77, 75, 75, .5); */
    transition: 0.2s all;
    z-index:1;
    width:fit-content;
overflow-y: scroll;
height:100%;
}
.map_business_down{
    position: relative;
    transition: 0.2s all;
    z-index:1;
    /* width:fit-content;
overflow-x: scroll; */
height:190px;
display: none!important;
}
.map_each_business {
    width:292px;

}
.popup_buss_name{
    font-size: 1.4em!important;
}
.map_businessBtn{
    position: absolute;
    top: 7px;
    left:100%;
    background: white;
    border: 1px solid rgb(134, 134, 134);
    color:rgb(134, 134, 134);
    border-radius:3px;
}
#selectionStore{
    /* background-color:rgb(115, 104, 175, .4); */
     display: none ;
    justify-content: center;
    align-items: center;
    overflow:scroll;
}
#selectionStores{
    height: 90vh;  
}
.subSelectionStore{
    height: 90vh;
}
.BZGridMapView{
    /* position:fixed;  */
    justify-content: center;
    align-items: center;
    top:65px;
}
.mapView,.gridView{
    overflow: scroll!important;
}

.subSelectionStore .BZGridMapView{
    width:100%!important;
}
.FormSmallScr{
    width:50%;
    left:25%;
    z-index: 6;
}
.ful_text_font{
    font-size:.8rem;
}
.animate-bz{
    animation:animatebz 1s linear forwards;
    height:100%;
    max-height: fit-content;
}

@keyframes animatebz{
    from{
        position:fixed;
        top:100vh;
    }
    to{
        position:fixed; 
        top:65px; 
    }
}
.FormDivPopup{
    margin-top:122px; 
}
.Address_Nav{
    position:absolute;
    top:0;
    height:65px;
    background: white;
}
.main-Div{
    width:fit-content;
    background-color: rgba(102, 93, 153, 0.5);
    border-radius:10px;
    border:1px solid silver;
    height: auto !important;
}
.Address_Form{
    margin:auto;
    width:fit-content;
font-size: .7em;
display:flex;
}   

.Form_Place{
    font-size: 0.9rem;
    margin-top:30px!important;
    display:none;
}

.animate-form{
    animation:animateform .5s linear forwards;
    animation-delay: .5s;
}
@keyframes animateform{
    from{
        display:block;
        position:relative; 
        top:30vh;   
    }
    to{
        position:fixed; 
        top:201px; 
        z-index:10!important;
        background: none;
        border:none;
    }
}
.Input_Address{
    position:relative;
    width:420px;
    height:50px;
    background-color: white;
    /* box-shadow: 0px 0px 2px 2px rgb(218, 218, 218); */
    border-top:1px solid silver;
    border-left:1px solid silver;
    border-bottom:1px solid silver;
}
.Dis_button{
    display:flex;
    background-color: white;
    width:110px;
    height:50px;
    padding-left:5px!important;
    border-top:1px solid silver;
    border-right:1px solid silver;
    border-bottom:1px solid silver;
    border-bottom-right-radius:5px!important;
    border-top-right-radius:5px!important;
}

.Dis_button .BZInputsTags{
    left:5px!important;
}


/* -----------react-player--------- */
.previewC{
    position: absolute;
    top:2px;
    right:2px;
    z-index:6;
    background: none;
    border:none;
    color:white;
    font-size: .7em;
}
.previewO{
    position: absolute;
    top:10px;
    right:10px;
    display:flex;
    justify-content: center;
    align-items: center;
    padding-left:8px;
    height:30px;
    width:30px;
    z-index:5;
    border-radius:50%;
    background: none;
    border:none;
    color:white; 
    font-size: .7em;
    border: 1px solid white;
}
.react-player__shadow{
    display: none;
}
.react-player__play-icon{
    position:absolute!important;
    left:5px!important;
    bottom:5px!important;
    z-index:6;
    border-width: 10px 0px 10px 13px!important;
}

.miles-text{
    font-size: .8em;
}

.select-item{
    width:90%;
    padding:0!important;
}
 .select-item-addr{
    background-color: rgb(233, 233, 233);
    width:100%;
}
.select-item-fulfil{
    position:relative;
}
.select-item-fulfil::after{
    content:"";
    position:absolute;
    left:0;
    bottom:-1px;
    height:4px;
    width:90%;
    left:5%;
   background:var(--color_purple);
     border-top-left-radius:5px;
    border-top-right-radius:5px;
}
.select-item-mile{
    background: #E9E9E9;
    width:90%;
}
.select-item-cate{
    background: #E9E9E9;
}
.dropdown_Distance .option{
display:none;
}
.dropdown_Distance{
    z-index:7!important;
    width:107px;
    left:0;
    background-color: white;
    color:black;
    height: 200px !important;
    overflow: scroll;
    border-left:1px solid silver;
}

.cross_addr_Form{
    display:none;
    color:transparent;
}

.home h1{
    font-size: 1.3em!important;
    text-shadow:1px 1px 2px white;
    font-weight: bolder;
    font-family: sans-serif;
}
/* .r-head{
    text-shadow: 1px 1px 1px grey;
} */
.r-head span{
    font-size: 2.5em!important;
    line-height: 0em;
    text-shadow: 2px 1px 1px grey;
}
.home p{
    font-size: .6em;
}
.Input_Address input{ 
border:none ;
outline:0;
}
.Address_Nav button{
    background-color: var(--color_purple);
    color:white;
    border-radius:5px;
    font-size:.7em;
}
.BZInputsTags{
    font-size: 10px;
    color: grey;
    top: 1px;
}
.Input_Address .BZInputsTags{
    margin-left:20px!important;
}
.react-rotating-text-cursor {
    font-size: .6em!important;
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
  }

  @keyframes blinking-cursor {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  #Inp_Addr{
      width: 90%;
  }
  #inp_Addr_Clear{
      border-right:1px solid silver;
  }


  .Input_Address, .Addr_list-inner{
    width:420px;
    color:black;
}
.Addr_list-inner{
    height:fit-content;
    z-index:7!important;
}

  .Addr_list{
      display:none;
      position:absolute;
      left:0;
      border:1px solid silver;
      top:100%;
      border-top:2px solid var(--color_purple);
      z-index:20!important;
      font-size: 0.9rem !important;
  }

  .Addr-List_Items{
      color:var(--color_purple);
      white-space: nowrap;

  }
  .error_msg{
      display:none;
      color:white;

      background-color: rgb(0, 0, 0, .6);
      margin-top:20px;
      z-index:0;
      font-size: .65em;
      padding:2px;
      width:fit-content;
  }
  .dropdown-toggle-bzForm{
      box-shadow: none !important;
      width: 100px;
      height: 55px;
  }

  @media screen and (max-width:1200px){
       .dropdown-toggle-bzForm{
      box-shadow: none !important;
      width: 100px;
      height: 50px;
  }

.Dis_button{ 
    padding:13px 0;
}
.FormDivPopup{
    margin-top:115px; 
}

  }
  /* remove one */
  .MapGridIconbar{
      height: fit-content !important;
  }
  @media screen and (max-width:1050px){
    .Input_Address, .Addr_list-inner{
        width: 390px;  
      } 
      .FormSmallScr{
        width:70%;
        left:15%;
    }
  }

  @media screen and (max-width:992px){
.FormDivPopup{
    margin-top:103px; 
}
}
@media screen and (max-width:945px){
    .Input_Address, .Addr_list-inner{
        width: 370px;  
      }  
}
@media screen and (max-width:895px){
    .r-head span{
        font-size: 2.5em!important;
    }

    /* .MapGridIconbar{
        margin-top: 10px !important;

    } */
    .dropdown-toggle-bzForm{
        width: 88px;
    }
    }
    @media screen and (max-width:820px){
        /* .MapGridIconbar{
            margin-top: 3px !important;
    
        } */
        #Inp_Addr{
            height: 100%;
        }

        .dropdown-toggle-bzForm{
            height: 40px;
        }
        .r-head span{
            font-size: 2em!important;
        }
        .Input_Address, .Addr_list-inner{
            height: 50px !important;
        }
    }
    @media screen and (max-width:769px){
        .BZInputsTags{
            font-size: 0.5rem !important;
        }

    .r-head span{
        font-size: 1.5em!important;
    }
        .FormDivPopup{
            margin-top:90px; 
        }
        .dropdown_Distance, .Dis_button{
            width:100px;
        }
    }


  @media screen and (max-width:700px){
    .dropdown-toggle-bzForm{
        width:80px;
     }
     #selectionStore{
        padding:0px !important;
    }

    .FormSmallScr .Address_Form{
    z-index:8!important;
    display:none;
    }
    .Form_Place{
        display:block;
        margin-top:5px!important;
        margin-left:20px!important;
    
    }
    ._BZFormBefore:before{
        content: "";
        position: absolute;
        background:rgba(0, 0, 0, .7);
        width:100vw;
        min-height: 90vh;
        top: 0;
        right: -15vw;
        z-index: 0;overflow: hidden;
        
    }
    .cross_addr_Form{
        color:white;
        border-radius: 50%;
        left:45%;
        background-color: var(--color_purple);
        top:385px;
        padding:2px;
        z-index:20;
        border: 1px solid white;
        cursor: pointer;
    }


    .FormSmallScr{
        z-index:10!important;
    }
  }
 
 @media screen and (max-width:600px){
     .ful_text_font{
         font-size:.6rem;
     }
    #Inp_Addr{
       font-size: 0.8rem !important;
    }
    .dropdown-toggle-bzForm{
       font-size: 0.8rem !important;

    }
    .main-Div{
        width:96%;
        left:2%;
    }
    .FormSmallScr .Address_Form{
        margin-left:-35px;
    }
 }
 @media screen and (max-width:577px){

    .dropdown-toggle-bzForm{
        width:110px;
     }
 }
 @media screen and (max-width:550px){
    .Input_Address, .Addr_list-inner{
        width: 320px;  
      } 
}

@media screen and (max-width:500px){

    .Input_Address, .Addr_list-inner{
        width: 300px;  
      } 

    .bs-cat small{
        font-weight:normal!important;
    }
    .BZ_NAV_ICONS{
        font-size: .9em;
        height:30px;
        width:30px;
        margin-top:5px;
    }

    .dropdown-toggle-bzForm{
        width:100px;
     }
    .Button_Address{
        height: 5vh;    
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.7rem;
    }
    .main-Div .Address_Form{
        height:55px;
    }

    .map_business{
        position: relative;
        bottom:0%;
        background-color:transparent;
        transition: 0.2s all; 
        width:100%;
        height:190px!important;
        overflow: scroll;
    }
    .map_each_business {
        margin-right:10px!important;
    }
    .map_businessBtn{
display: none;
    }
    .map_business_up{
display: none;
    }
    .map_business_down{
        display: block!important;
    }
    .map_business_full_popup{
        display: none!important;
    }
}
@media screen and (max-width:450px){
    .cross_addr_Form{
        font-size: .9em!important;
    
    }
    .FormSmallScr{
        width:80%;
        left:10%;
        z-index: 6;
    }
    
    ._BZFormBefore:before{
        left: -10vw;
    }
    .FormSmallScr .Address_Form{
        margin-left:-9vw;
        width:98vw;
    }

.FormSmallScr .Dis_button{
    width:21vw;
}
.FormSmallScr .dropdown_Distance{
    width:20.5vw;
}
.FormSmallScr .Input_Address{
    width:79vw!important;  
  } 
  .FormSmallScr .Addr_list-inner{
    width:77vw!important;  
  } 
  .FormSmallScr .btn-group {
    margin-left:30px!important;
}
.dropdown-toggle-c{
    margin-left:30px!important;
}
.miles-text{
    line-height: 1em;
    font-size: .8em!important;
}
.main-Div .Dis_button, .main-Div .dropdown_Distance{
    width:80px;
}
.main-Div .Input_Address, .main-Div .Addr_list-inner{
    width:230px!important;  
  } 
}


@media screen and (max-width:376px){
    .Button_Address{
        height: 5vh;    
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.7rem;
    }

    .dropdown-toggle-bzForm{
        width: 4.5rem;
    }
}

/* bz first menu icon */
