
/* done */

.cen-store{
    line-height:1em;
}

.home{
    font-size: 1.5em;
    color:var(--color_white);
}
.input-s, .btn-s{
    padding:7px 10px;
    border:none;
    outline:none;
}
.input-s{
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border:1px solid silver;
}




@media screen and (max-width:1024px){
    .cart{
display: flex;
align-items: center;
width:fit-content;
    }
}
@media screen and (max-width:600px){
    .home{
font-size: 1.1em;
    }
    .cen-store{
font-size: .9em;
    }
    .cart{
        padding:7px 11px; 
        font-size: .9em;
    }
}




