/* done */

.departments{
    margin-top:120px!important;
    background: rgb(224, 224, 224);
}
.departments h2{
    padding:20px 0;
    box-sizing: border-box;
    font-size: 1.8em;
    font-weight: bolder;
}
.catagory h5:hover{
    text-decoration: underline;
    cursor: pointer;
}
.department-box{
width:80%;
   margin: auto;
    display: grid;
    grid-template-columns:repeat(4, 25%);
    grid-gap:7px;
    box-sizing: border-box;
}
.catagory{
    position: relative;
    overflow: hidden;
    padding:20px;
    box-sizing: border-box;
    background: white;
    border-radius: 5px;
    z-index: 0;
}
.back-img{
    /* width: auto; */
    /* max-width: 300px; */
    width:200px;
    position: absolute;
    top:-35px;
    right:-35px;
    z-index:-1 !important;
    clip-path: ellipse(60% 50% at 65% 23%);
    transform: rotate(20deg);
}
.catagory h5{
z-index:100 !important;
}
.catagory p{
    line-height: 1.2em;
    color:#757575;
}
.catagory p:hover{
    text-decoration: underline;
    cursor: pointer;
}
.catagory span{
    position: absolute;
    color:var(--color_purple);
    font-weight: bold;
    bottom:20px;
}
.catagory span:hover{
    text-decoration: underline;
    cursor: pointer;
}
.db_dp_name{
    font-size: 0.95rem;
}
@media screen and (max-width:500px){
    .db_dp_name{
        font-size: 0.75rem !important;
    }
}



