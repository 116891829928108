    .maincategoriesNav{
    width: 100vw;
    overflow-x: auto;
    overflow-y:hidden;
    height:7vh;
    z-index:3;
    background:#F7F7F7!important;
    border-bottom:1px solid #ebebeb;
    box-shadow:0px  1px 5px 1px rgb(240, 240, 240);
}
.categoriesNav{
    display: flex;
    align-items: center;
    height: 6.7vh;
    padding: 0 1rem !important;
    color:rgb(119, 118, 118);
    font-weight: 600;
}
@media screen and (max-width:500px){
    .maincategoriesNav{
         padding: 0 1rem !important;
     }
}
.categoriesNav > li{
   list-style: none;
   margin-right: 1.5rem ;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}
.categoriesNav > li > a{
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
}

.mainCategories .SingleProduct{
    transition: 0.2s all;
    cursor: pointer;
}

.maincategoriesNav .selectedCatAnchor{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom:5px solid var(--color_purple);
}
/* categories navbar */
.scrollspyRootEl{
    margin-top: 1vh;
}